import axios from 'axios'
import router from "@/router";
import ElementUI from 'element-ui';
import { API_URL, UPLOAD_URL,ADMIN_PAGE } from '@/utils/constants';

const request = axios.create({
    baseURL: API_URL,
    timeout: 5000
})
// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
    let membertoken = localStorage.getItem("membertoken")
    if (user) {
        config.headers['token'] = user.token;  // 设置请求头
    }
    if (membertoken){
        config.headers['membertoken'] = membertoken;  // 设置请求头
    }
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(response => {
        // 关闭Loading动画
        ElementUI.Loading.service({
            fullscreen: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.3)', // 透明度为 0.5 的黑色背景
            customClass: 'my-custom-loading'
        }).close();

        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        // 当权限验证不通过的时候给出提示
        if (res.code === '401') {
            ElementUI.Message({
                message: res.msg,
                type: 'error'
            });
            router.push('/')
        }
        // 当权限验证不通过的时候给出提示
        if (res.code === '4010') {
            localStorage.removeItem("membertoken")
            ElementUI.Message({
                message: res.msg,
                type: 'error'
            });
            router.push('/')
        }
        if (res.code === '600') {
            ElementUI.Message({
                message: res.msg,
                type: 'error'
            });
        }
        return res;
    },
    error => {
        // 关闭Loading动画
        ElementUI.Loading.service({
            fullscreen: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.3)', // 透明度为 0.5 的黑色背景
            customClass: 'my-custom-loading'
        }).close();
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)


export default request

