import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import { ADMIN_PAGE } from '@/utils/constants';

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'indexPage',
        component: () => import("../views/front/IndexPage.vue")
    },
    {
        path: '/uploadPage',
        name: 'uploadPage',
        component: () => import("../views/front/UploadPage.vue")
    },
    {
        path: '/Details',
        name: 'details',
        component: () => import("../views/front/Details.vue")
    },
    {
        path: '/loginPage',
        name: 'LoginPage',
        component: () => import('../views/Login.vue')
    },
    // {
    //     path: '/register',
    //     name: 'Register',
    //     component: () => import('../views/Register.vue')
    // },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/404.vue')
    }

];



const router = new VueRouter({
    mode: 'history',
    routes
})

// 重置路由
export const resetRouter = () => {
    router.matcher = new VueRouter({
        mode: 'history',
        routes
    })
}
// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
    const  pagePath = ADMIN_PAGE;
    const storeMenus = localStorage.getItem("menus");
    const menus = JSON.parse(storeMenus)
    var path1 ='/';
    if (menus){
        path1 = menus[0].path;
    }
    if (storeMenus) {
        // 拼装动态路由
        const manageRoute = { path: pagePath, name: 'Manage', component: () => import('../components/Manage.vue'), redirect: pagePath+path1, children: [
                { path: pagePath+'/person', name: '个人信息', component: () => import('../views/Person.vue')},
                { path: pagePath+'/password', name: '修改密码', component: () => import('../views/Password.vue')},
            ] }
        menus.forEach(item => {
            if (item.path) {  // 当且仅当path不为空的时候才去设置路由
                let itemMenu = { path: pagePath+item.path, name: item.name, component: () => import('../views/' + item.pagePath + '.vue')}
                manageRoute.children.push(itemMenu)
            } else if(item.children.length) {
                item.children.forEach(item => {
                    if (item.path) {
                        let itemMenu = { path: pagePath+item.path, name: item.name, component: () => import('../views/' + item.pagePath + '.vue')}
                        manageRoute.children.push(itemMenu)
                    }
                })
            }
        })

        // 获取当前的路由对象名称数组
        const currentRouteNames = router.getRoutes().map(v => v.name)
        console.log(manageRoute);
        // console.log(currentRouteNames);
        if (!currentRouteNames.includes('Manage')) {
            // 动态添加到现在的路由对象中去
            router.addRoute(manageRoute)
        }
    }
    // console.log(router.getRoutes());
}

// 每次刷新页面都要重新设置路由，否则路由就会被重置
setRoutes()

router.beforeEach((to, from, next) => {
    // console.log('to>>>');
    // console.log(to);
    // console.log('from>>>');
    // console.log(from);
    // console.log('next>>>');
    // console.log(next);

    localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称
    store.commit("setPath")
    // console.log(to);
    if (!to.matched.length) {
        const menus = localStorage.getItem("menus")
        if (!menus) {
            next("/")
        } else {
            next("/404")
        }
    } else {
        next()
    }
})

export default router
