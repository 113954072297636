import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/gloable.css'
import request from "@/utils/request";

import { API_URL, UPLOAD_URL,ADMIN_PAGE } from '@/utils/constants';
// Vue.prototype.$API_URL = API_URL;
// Vue.prototype.$UPLOAD_URL = UPLOAD_URL;

Vue.prototype.API_URL = API_URL;
Vue.prototype.UPLOAD_URL = UPLOAD_URL;
Vue.prototype.ADMIN_PAGE = ADMIN_PAGE;

Vue.config.productionTip = false

Vue.use(ElementUI, { size: "mini" });

Vue.prototype.request=request

window.mode = 'drama_promotion';

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
