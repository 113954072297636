console.log('process.env.VUE_APP_BASE_URL',process.env.VUE_APP_BASE_URL)
// export const API_URL = 'https://ht.0ob1xu.com/';
// export const UPLOAD_URL = 'https://ht.0ob1xu.com/file/oss/upload';
export const API_URL = process.env.VUE_APP_BASE_URL+'api';
export const UPLOAD_URL = process.env.VUE_APP_BASE_URL+'api/file/oss/upload';

// export const API_URL = 'http://localhost:9010';
// export const UPLOAD_URL = 'http://localhost:9010/file/oss/upload';

export const APP_NAME = 'My Awesome App';
// export const ADMIN_PAGE = '';
export const ADMIN_PAGE = '/adminPage';